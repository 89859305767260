#form{
  background-color: rgba(97, 98, 101, 0.066);
  backdrop-filter: blur(51px);
  border: 2px solid rgba(0, 0, 0, 0.255);
  border-radius: 16px;
}


.nav{
  
 
  background-size: 100px;
  background-image: url('../../../public/background.png');
 
}
.input{
  width: 310px;
  padding: 10px;
  margin-top: 16px;
  background-color: transparent;
  border: 2px solid rgba(5, 4, 55, 0.31);
  border-radius: 7px;
  color: #000000;
  
}

.input:first-child{
  margin-top: 90px;
  
}

.input:hover{
  /* background: rgb(2,0,36);
background: linear-gradient(90deg, rgba(2, 0, 36, 0.305) 0%, rgba(23, 23, 136, 0.311) 49%, rgba(0, 213, 255, 0.289) 100%); */
  border: 2px solid rgba(0, 0, 0, 0.315);
  padding: 12px;
  /* animation: some .3s linear; */
  transition: .2s linear;
  color: #0a0909;
}
.button{
margin-top: 40px;
backdrop-filter: blur(51px);
/* background-color: rgba(66, 80, 133, 0.162); */
/* border: 2px solid rgba(137, 43, 226, 0.496); */

}

@keyframes some{
  0%{
      padding: 10px;
      background-color: transparent;
  }
  
  100%{
      padding: 12px;
      background-color: rgba(137, 43, 226, 0.61) ;
  }
  
}

.btn-three {
  color: #575454;
  transition: all 0.5s;
  position: relative;
  padding: 6px;
  width: 90px;
  margin-top: 50px;
}
.btn-three::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(255,255,255,0.1);
  transition: all 0.3s;
}
.btn-three:hover::before {
  opacity: 0 ;
  transform: scale(0.5,0.5);
}
.btn-three::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
  border: 1px solid rgba(255,255,255,0.5);
  transform: scale(1.2,1.2);
}
.btn-three:hover::after {
  opacity: 1;
  transform: scale(1,1);
}

.loading {
  height: 25px;
  width: 25px;
  border-radius: 50%; /* Use 50% for perfect circle */
  border: 6px solid rgb(255, 255, 255);
  border-top: 6px solid rgb(59,130,246);
  animation: loader 1.5s infinite linear; 
}

@keyframes loader {
  0% {
      transform: rotate(0deg); /* Start position */
  }
  100% {
      transform: rotate(360deg); /* One full rotation */
  }
}

.btn-sub{
transition: .4s;

}

.load{
transition: 0.7s;
width: 54px;
height: 50px;
display: flex;
justify-content: center;
align-items: center;
}