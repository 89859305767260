.deleteStandard-form{
        
   display: grid;
   grid-template-columns:1fr 2fr ;
   
    }
    
    .type-delete{
        background-color: rgba(197, 197, 249, 0.385);
        width: 85%;
        padding: 10px;
    }
@media (min-width:670px) {
    .deleteStandard-form{

    grid-template-columns:1fr 1fr ;
    }
   
    
}
@media (max-width:670px) {
    .type-delete{
        
        width: 300px;
        height: 50%;
        padding: 10px;
    }
    
}
