.addStandard-main {
    display: grid;

    height: 100vh;

}
.checkbox-form{
    overflow-y: scroll;
    height: 100px;
}
.checkbox-form::-webkit-scrollbar-thumb {
    background: #000;
    border-radius: 5px;
}
#style-7::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
	border-radius: 10px;
}

#style-7::-webkit-scrollbar
{
	width: 3px;
	background-color: #F5F5F5;
}

#style-7::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	background-image: -webkit-gradient(linear,
									   left bottom,
									   left top,
									   color-stop(0.44, rgb(122,153,217)),
									   color-stop(0.72, rgb(73,125,189)),
									   color-stop(0.86, rgb(28,58,148)));
}
.type-3Form {
    background-color: rgba(197, 197, 249, 0.385);
    width: 85%;
    padding: 10px;
    /* display: grid;
    grid-template-columns: 1fr 1fr; */
}

.bndMainform {
    background: rgb(168, 203, 224);
    background: linear-gradient(180deg, rgba(168, 203, 224, 1) 0%, rgba(9, 153, 238, 1) 28%, rgba(18, 106, 154, 1) 69%, rgba(104, 142, 163, 0.9831582291119573) 97%);
}
.get-main {
    background: rgb(168, 203, 224);
    background: linear-gradient(180deg, rgba(168, 203, 224, 1) 0%, rgba(9, 153, 238, 1) 28%, rgba(18, 106, 154, 1) 69%, rgba(104, 142, 163, 0.9831582291119573) 97%);
}
.deleteMain {
    height: 100%;
    background: rgb(168, 203, 224);
    background: linear-gradient(180deg, rgba(168, 203, 224, 1) 0%, rgba(9, 153, 238, 1) 28%, rgba(18, 106, 154, 1) 69%, rgba(104, 142, 163, 0.9831582291119573) 97%);
}
.error-message {
    opacity: 0; /* initially hide the error message */
    transition: opacity 1s, transform 1s; /* define the transition properties */
    transform: translateX(100%); /* move the element out of view initially */
  }

@media (min-width: 700px){
    .addStandard-main {
        /* grid-template-columns: repeat(2, minmax(0, 1fr)) */
        grid-template-columns: 1fr 5fr; 
    
    }
    
}
@media (max-width: 700px){
    .addStandard-main {
        /* grid-template-columns: repeat(2, minmax(0, 1fr)) */
        grid-template-rows: 1fr 9fr; 
    
    }
    
}