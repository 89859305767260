.getStandards-second{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   background: rgb(168,203,224);
background: linear-gradient(180deg, rgba(168,203,224,1) 0%, rgba(9,86,238,1) 28%, rgba(18,73,154,1) 69%, rgba(75,102,117,0.9831582291119573) 97%);
   
}
.bg-red-900{
   background: rgb(168,203,224);
   background: linear-gradient(180deg, rgba(168,203,224,1) 0%, rgba(9,86,238,1) 28%, rgba(18,73,154,1) 69%, rgba(75,102,117,0.9831582291119573) 97%);
      
}