.varification-container{
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    column-gap: 5px;
    background-color: rgba(64, 0, 255, 0.69);
}

.varification-div{
    display: flex;
    flex-direction: column;
    background: rgb(238,174,202);
    background: radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%);
    height: 92vh;
    justify-content: center;
    width: 100%;
    padding: 10px;
    align-items: center
}
.varification-display{
    display: flex;
    flex-direction: column;
    background: rgb(148,187,233);
background: radial-gradient(circle, rgba(148,187,233,1) 0%, rgba(238,174,202,1) 100%);
    height: 92vh;
    justify-content: center;
    width: 100%;
    padding: 10px;
    align-items: center
}
.varification-form{
    background-color: rgba(150, 88, 226, 0.307);
    border: 3px solid #ff00d93c;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
    height: 70%;
    border-radius: 2px;
}
.varification-data{
    background-color: rgba(255, 0, 230, 0.181);
    border: 3px solid #ff00ea5c;
    display: flex;
    width: 60%;
    height: 70%;
    border-radius: 2px;
    align-content: center;
    flex-direction: column;
    flex-wrap: wrap
}
    .btn-three {
        color: #ffffff;
        transition: all 0.5s;
        position: relative;
        padding: 6px;
        width: 90px;
        margin-top: 50px;
      }
      .btn-three::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-color: rgba(255,255,255,0.1);
        transition: all 0.3s;
      }
      .btn-three:hover::before {
        opacity: 0 ;
        transform: scale(0.5,0.5);
      }
      .btn-three::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0;
        transition: all 0.3s;
        border: 1px solid rgba(255,255,255,0.5);
        transform: scale(1.2,1.2);
      }
      .btn-three:hover::after {
        opacity: 1;
        transform: scale(1,1);
      }
  