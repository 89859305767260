.welcome-main {
  clip-path: polygon(50% 0%, 100% 0, 100% 75%, 0 19%, 0 0);
  background: rgb(168, 203, 224);
  background: linear-gradient(180deg, rgba(168, 203, 224, 1) 0%, rgba(9, 86, 238, 1) 28%, rgba(18, 73, 154, 1) 69%, rgba(75, 102, 117, 0.9831582291119573) 97%);
  height: 100%;
  -webkit-user-select: none; /* Safari */
-ms-user-select: none; /* IE 10 and IE 11 */
user-select: none;
 
}

.btn-three1 {
  color: #FFF;
  transition: all 0.5s;
  position: relative;
  padding: 6px;
  width: 190px;
  margin-top: 50px;
  left: 42%;
  -webkit-user-select: none; /* Safari */
-ms-user-select: none; /* IE 10 and IE 11 */
user-select: none;
}
.btn-three1::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.104);
  transition: all 0.3s;
}
.btn-three1:hover::before {
  opacity: 0 ;
  transform: scale(0.5,0.5);
}
.btn-three1::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
  border: 1px solid rgba(255,255,255,0.5);
  transform: scale(1.2,1.2);
}
.btn-three1:hover::after {
  opacity: 1;
  transform: scale(1,1);
}

@import url("https://fonts.googleapis.com/css2?family=Salsa&display=swap");

body {
  background-color: #ffffff;
}

.welcome-image{
height: 100px;
width: 100px;
object-fit: contain;
mix-blend-mode: color-burn;
position: relative;
left: 48%;
}

svg {
font-family: "Times New Roman", Times, serif;
  width: 100%;
  height: 100%;
}

svg text {
  animation: stroke 5s linear;
  stroke-width: 2;
  stroke: #365FA0;
  font-size: 100px;
}

@keyframes stroke {
  0% {
      fill: rgba(72, 138, 204, 0);
      stroke: rgba(54, 95, 160, 1);
      stroke-dashoffset: 25%;
      stroke-dasharray: 0 50%;
      stroke-width: 2;
  }

  70% {
      fill: rgba(72, 138, 204, 0);
      stroke: rgba(54, 95, 160, 1);
  }

  80% {
      fill: rgba(72, 138, 204, 0);
      stroke: rgba(54, 95, 160, 1);
      stroke-width: 3;
  }

  100% {
      fill: rgba(72, 138, 204, 1);
      stroke: rgba(54, 95, 160, 0);
      stroke-dashoffset: -25%;
      stroke-dasharray: 50% 0;
      stroke-width: 0;
  }
}

.wrapper {
  background-color: #ffffff00
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.marquee {
  position: absolute;
  white-space: nowrap;
  font-size: large;
  overflow: hidden;
  animation: marquee 15s linear infinite; /* Adjust duration as needed */
}