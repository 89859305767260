.login-main{
    background-image: url('../../../../public/background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
}
.login-form{
    background-color: rgba(213, 213, 255, 0.612);
    height: 40rem;
}
.login-form:first-child{
    justify-content: center;
    align-items: center;
}
.login-input:focus{
    box-shadow: 0 2px 15px 0 rgba(55, 0, 255, 0.5);
}
.incorrect-login{
    box-shadow: 0px 5px 30px 0px rgba(255, 0, 0, 0.8);

}

.login-button:hover{
   /* border: 3px; */
    /* width: 100%;
    height: 100%; */
    
    animation: buttonAnimation 1s infinite cubic-bezier(0.895, 0.03, 0.685, 0.22) forwards;
    
}
.login-submitting{
    animation: wrongAnimation 3s infinite ease-in-out forwards;
}
@keyframes buttonAnimation {
    0%{
        /* background-color: rgba(0, 0, 255, 0.397); */
        border-bottom: 4px solid rgba(153, 0, 255, 0.73);
    }
    25%{
        /* background-color: rgba(0, 0, 255, 0.397); */
        border-left: 4px solid rgba(153, 0, 255, 0.73);
        
    }
    50%{
        /* background-color: rgba(0, 0, 255, 0.397); */
        border-top: 4px solid rgba(153, 0, 255, 0.73);
        
    }
    75%{
        /* background-color: rgba(0, 0, 255, 0.397); */
        
        border-right: 4px solid rgba(153, 0, 255, 0.73);
        
    }
    100%{
        /* background-color: rgba(0, 0, 255, 0.397); */
        border-right: 4px solid rgba(153, 0, 255, 0.73);

    }
}
@keyframes wrongAnimation {
    0%{
        background: rgba(0, 0, 255, 0.397);
        
    }
    
    50%{
        background-color: rgba(255, 0, 0, 0.73);
        border: 4px solid rgba(255, 0, 0, 0.73);
        
    }
    100%{
        background: rgba(0, 0, 255, 0.397);
        
    }
}

@media (min-width: 640px) {
    
}