.main{
   min-height: 100vh;
   width: 100vw;
    /* background: #000; */
    display: grid;
    font-family: "Roboto Slab", serif;
    grid-template-columns: 1fr 8fr;
}
.sideBar{
  background-color: #212529;
  padding: 2px;
  padding-left: 8px;
  padding-right: 8px;
  
}

.menue{
  top: calc(100% + .25rem);
  background-color: white;
  width: 90%;
  padding: .25rem;
  border-radius: 3px;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.5);
  
}

.btn-group {
  position: relative;
  display: inline-block;
}

.btn:hover{
  background-color: #161b20;
}

.btn {
  display: inline-block;
  font-weight: 600;
  size: 12pt;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  color: white;
  font-size: 15pt;
  transition: color 0.15s ease-in-out, background-color 0.85s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.85s ease-in-out;
    background-color: #242c34;

}

.btn-logout:hover{
  background-color: red;
}
.btn-logout{
  display: inline-block;
  position: absolute;
  width: 90%;
  bottom: 1%;
  font-weight: 600;
  size: 12pt;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  color: white;
  font-size: 15pt;
  transition: color 0.15s ease-in-out, background-color 0.85s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.85s ease-in-out;
    background-color: rgba(255, 0, 0, 0.701);
}



.dropdown-menu {
  display: none;
  top: 0;
  /* position: relative; */
  position: absolute;
  left: calc(100% + .5rem);
  background-color:  #212529eb;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  box-shadow: 0 2px 5px 0  rgba(0, 0, 0, 0.5);
}

.dropdown-menu li {
  padding: 0.25rem 1.5rem;
}

.btn-group:hover  .dropdown-menu {
  display: block;
}
