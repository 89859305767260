.loading {
    height: 40px;
    width: 40px;
    border-radius: 50%; /* Use 50% for perfect circle */
    border: 6px solid rgb(14, 114, 213);
    border-top: 6px solid white;
    animation: loader 1.5s infinite linear; /* Changed ease-in to linear for smoother animation */
}

@keyframes loader {
    0% {
        transform: rotate(0deg); /* Start position */
    }
    100% {
        transform: rotate(360deg); /* One full rotation */
    }
}

.iframe-down{
    background-color: transparent;
border: 1px solid #ccc; /* Add a subtle border */
border-radius: 8px; /* Round the corners */
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a light shadow */
padding: 10px; /* Add some padding */

}