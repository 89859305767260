.table-main{
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100vh;
    
}
.create-table-main{
    display: grid;
    grid-template-columns: 1fr ;
    height: 100vh;
    
}

.table-Section1{

    background: rgb(168, 203, 224);
    background: linear-gradient(180deg, rgba(168, 203, 224, 1) 0%, rgba(9, 153, 238, 1) 28%, rgba(18, 106, 154, 1) 69%, rgba(104, 142, 163, 0.9831582291119573) 97%);

}

.table-two{
    height: 46%;
}